import React, { Component } from "react";
import "./OtpForm.css"
import "./Modal.css"
import config from "./config.json"


class Yookassa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    componentDidMount(e) {
        if(this.state.isLoaded || !this.props.confirmationToken){
            return;
        }
        this.state.isLoaded = true;
        const script = document.createElement('script');
        script.src = config.yooKassa.scriptSrc;
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            this.state.isLoaded = true;
            if (window.YooMoneyCheckoutWidget && !document.getElementById("yookassa-wrap")[0] && this.props.confirmationToken) {
                const checkout = new window.YooMoneyCheckoutWidget({
                    confirmation_token: this.props.confirmationToken,
                    return_url: config.yooKassa.returnUrl,
                    customization: {
                        colors: {                      
                            background: "#19232e"
                        },
                        modal: true
                    },
                    error_callback: function (error) {
                        window.console.error(error);
                    }
                });
                checkout.render('yookassa-wrap')
                    .then(() => {

                    });
            }
            else {
                window.console.error("test");
            }
        };
    }

    render() {
        var { isShow } = this.props;
        return (
            <div id="yookassa-wrap" className="yookassa-wrap">
            </div>
        );
    }
}

export default Yookassa;